import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.css';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useContext, useState } from 'react';
import { getRestaurantThunk } from './store/restaurant';
import Context from './Context';
import { Route, Routes, useLocation } from 'react-router-dom';
import OnlineOrder from './Pages/OnlineOrder';
import CheckoutPage from './Pages/Checkout';
import ScrollToTop from './elements/ScrollToTop';
import OrderConfirmed from './Pages/OrderPlaced';
import Header from './elements/Header';
import Home from './Pages/Home';
import Receipt from './Pages/Receipt';
import Menu from './Pages/menu';
import ContactUs from './Pages/Contact';

function App() {
  const dispatch = useDispatch();
  const restaurant = useSelector(state => state.restaurants.restaurant);
  const { restaurantId } = useContext(Context);

  useEffect(() => {
    if (restaurantId) {
      dispatch(getRestaurantThunk(restaurantId));
    }
  }, [dispatch, restaurantId]);




  return (
    <div className="App">

      <Header />

  
      <ScrollToTop />
      <Routes>
        <Route exact path='/' element={<Home restaurant={restaurant}/> } />
        <Route path="/order" element={<OnlineOrder restaurant={restaurant} />} /> 
        <Route path='/checkout' element={<CheckoutPage fee={restaurant?.Restaurant_Fees[0]} />} />
        <Route path='/orderconfirmed/:tip/:name/:phone/:coupon/:address' element={<OrderConfirmed />} />
        <Route path='/order/:id/:restaurant_id' element={<Receipt />} />
        <Route path='/menu' element={<Menu  restaurant={restaurant}/>} />
        <Route path='/contact' element={<ContactUs />} />
      </Routes>
    </div>
  );
}

export default App;
