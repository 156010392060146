import { useNavigate } from "react-router-dom"
import heroImage from '../asset/Images/hero.png'

export default function HeroSection(){
  const navigate = useNavigate()
    return(
      <div className='container'>
      <div className='row'>
        <div className='col-md-3 d-flex align-items-center'>
          <div className='banner-content '>
            <h1>Authentic Hongkong Style</h1>
            <p>Enjoy authentic HongKong flavors at our cozy restaurant in Minneapolis, MN</p>
            <button className='btn btn-primary  mt-3' onClick={() => navigate('/order')}>Make an order</button>
          </div>     
        </div>

        <div className='col-md-9'>
          <img
            className='hero-background-image'
            src={heroImage}
            alt="Background"
          />
        </div>
      </div>
    </div>
    )
}
