import { FaClock, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';


export default function InfoSection(){
    return(
      <div className="container section bg-white  rounded py-4 mt-5">
      <div className="row text-center align-items-center">
        <div className="col-md-4 d-flex flex-column align-items-center" >
        <div className="icon-circle  mb-2 ">
            <FaClock color="white" />
          </div>
          <h6 className="font-weight-bold mb-0">10:00am – 9:00pm</h6>
          <small className="text-muted">Open hours</small>
        </div>

        <div className="col-md-4 d-flex flex-column align-items-center border-left border-right">
          <div className="icon-circle  mb-2 mt-4">
            <FaMapMarkerAlt color="white" />
          </div>
          <h6 className="font-weight-bold mb-0">815 E 78th St, Minneapolis, MN 55420

</h6>
          <small className="text-muted">Find us</small>
        </div>

        <div className="col-md-4 d-flex flex-column align-items-center">
          <div className="icon-circle  mb-2 mt-4">
            <FaPhone color="white" />
          </div>
          <a href='tel:6123799472' >
          <h6 className="font-weight-bold mb-0">612-379-9472

</h6>
</a>
          <small className="text-muted">Call us</small>
        </div>
      </div>
    </div>
    )
}
