import React from 'react';
import { FaFacebookF, FaTwitter, FaYoutube, FaLinkedinIn } from 'react-icons/fa';
import logo from '../asset/Images/logo.png'
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Logo and Description */}
        <div className="footer-section">
          <div className="footer-logo w-100">
            <img
              src={logo}
              alt="Hongkong Logo"
              className='w-50'
            />
          </div>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>
          <a href="#" className="license-info">Image License Info</a>
          <div className="footer-socials">
            <FaFacebookF />
            <FaTwitter />
            <FaYoutube />
            <FaLinkedinIn />
          </div>
        </div>

        {/* Opening Times */}
        <div className="footer-section">
          <h4>Opening Times</h4>
          <ul className="opening-times">
            <li><strong>Monday</strong> 1:00pm - 10:00pm</li>
            <li><strong>Tuesday</strong> 1:00pm - 10:00pm</li>
            <li><strong>Wednesday</strong> 1:00pm - 10:00pm</li>
            <li><strong>Thursday</strong> 1:00pm - 11:00pm</li>
            <li><strong>Friday</strong> 1:00pm - 11:00pm</li>
            <li><strong>Saturday</strong> 12:00pm - 11:00pm</li>
            <li><strong>Sunday</strong> 12:00pm - 9:00pm</li>
          </ul>
        </div>

        {/* Contact Information */}
        <div className="footer-section">
          <h4>Contact Us</h4>
          <p>Hongkong Noodles</p>
          <p>815 E 78th St, Minneapolis, MN 55420</p>
          <p>612-379-9472</p>
          <p>Powered by Owenmenu</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
