import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import logo from '../asset/Images/logo.png';
import './Header.css';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [scrollDistance, setScrollDistance] = useState(0);
  const SCROLL_UP_THRESHOLD = 1000;

  // Function to handle closing menu on link click for mobile
  const handleNavClick = (path) => {
    navigate(path);
    if (window.innerWidth < 992) { // Close menu if in mobile view
      document.querySelector('.navbar-toggler').click();
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname === '/order') {
        const currentScrollY = window.scrollY;

        if (currentScrollY === 0) {
          setIsVisible(true);
          setScrollDistance(0);
        } else {
          const isScrollingDown = currentScrollY > lastScrollY;

          if (isScrollingDown) {
            setIsVisible(false);
            setScrollDistance(0);
          } else {
            setScrollDistance((prevDistance) => prevDistance + (lastScrollY - currentScrollY));
            if (scrollDistance >= SCROLL_UP_THRESHOLD) {
              setIsVisible(true);
            }
          }
        }

        setLastScrollY(currentScrollY);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY, location.pathname, scrollDistance]);

  useEffect(() => {
    if (
      ['/', '/checkout', '/menu', '/contact'].includes(location.pathname)
    ) {
      setIsVisible(true);
    }
  }, [location.pathname]);

    // Effect to adjust the position of the category menu section
    useEffect(() => {
      const categorySection = document.querySelector('.category-menu-wrapper');
      if (categorySection) {
        if (isVisible) {
          categorySection.style.top = '65px'; // Adjust this value based on the height of your header
          categorySection.style.transition = 'top 0.8s';
        } else {
          categorySection.style.top = '0';
        }
      }
    }, [isVisible]);

  return (
    <Navbar
      expand="lg"
      className={`header ${isVisible ? 'visible' : 'hidden'}`}
      bg="white"
      variant="light"
      fixed="top"
    >
      <Container>
        <Navbar.Brand onClick={() => navigate('/')} className="header-logo">
          <img src={logo} alt="Restaurant Logo" className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto text-center">
            <Nav.Link onClick={() => handleNavClick('/')}>Home</Nav.Link>
            <Nav.Link onClick={() => handleNavClick('/menu')}>Menu</Nav.Link>
            <Nav.Link onClick={() => handleNavClick('/order')}>Order</Nav.Link>
            <Nav.Link onClick={() => handleNavClick('/checkout')}>Checkout</Nav.Link>
            <Nav.Link onClick={() => handleNavClick('/contact')}>Contact</Nav.Link>
          </Nav>
          <Nav className="ms-3">
            <button
              className="btn btn-outline-dark order-now-button text-black"
              onClick={() => handleNavClick('/order')}
            >
              Order Now
            </button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
