import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaMapMarkerAlt, FaPhoneAlt, FaClock, FaFacebookF, FaTwitter, FaYoutube, FaLinkedinIn } from 'react-icons/fa';

const ContactUs = () => {
  return (
    <div style={{ marginTop: '150px' }}>
      <Container className="text-center">
        <h1 style={{ color: '#ba9131', marginBottom: '30px' }}>Contact Us</h1>
        
        {/* Location and Contact Info */}
        <Row className="justify-content-center mb-5">
          <Col md={6} className="text-center">
            <h4 style={{ color: '#ba9131' }}>
              <FaMapMarkerAlt /> Our Location
            </h4>
            <p>Hongkong Noodles</p>
            <p>815 E 78th St, Minneapolis, MN 55420</p>
            <p><FaPhoneAlt /> 612-379-9472</p>
          </Col>
        </Row>

        {/* Opening Hours */}
        <Row className="justify-content-center">
          <Col md={6} className="text-center">
            <h4 style={{ color: '#ba9131' }}>
              <FaClock /> Opening Times
            </h4>
            <ul className="list-unstyled">
              <li>Monday: 1:00pm - 10:00pm</li>
              <li>Tuesday: 1:00pm - 10:00pm</li>
              <li>Wednesday: 1:00pm - 10:00pm</li>
              <li>Thursday: 1:00pm - 11:00pm</li>
              <li>Friday: 1:00pm - 11:00pm</li>
              <li>Saturday: 12:00pm - 11:00pm</li>
              <li>Sunday: 12:00pm - 9:00pm</li>
            </ul>
          </Col>
        </Row>

        {/* Social Media Icons */}
        <Row className="justify-content-center mt-4">
          <Col md={6} className="text-center">
            <h4 style={{ color: '#ba9131' }}>Follow Us</h4>
            <div className="d-flex justify-content-center gap-3 mt-2">
              <FaFacebookF size={24} style={{ color: '#ba9131' }} />
              <FaTwitter size={24} style={{ color: '#ba9131' }} />
              <FaYoutube size={24} style={{ color: '#ba9131' }} />
              <FaLinkedinIn size={24} style={{ color: '#ba9131' }} />
            </div>
          </Col>
        </Row>
      </Container>

      {/* Custom Styles */}
      <style jsx>{`
        h1, h4 {
          font-weight: bold;
        }
        ul {
          padding-left: 0;
        }
        li {
          font-size: 1.1rem;
        }
      `}</style>
    </div>
  );
};

export default ContactUs;
