import { useNavigate } from 'react-router-dom';
import ethoImage from '../asset/Images/etho.png'

export default function BannerSection(){
  const navigate = useNavigate()
    return (
        <div className="container my-5 section banner-section text-center">
          <div className="row align-items-center">
            
            {/* Image Section */}
            <div className="col-md-6">
              <img
                src={ethoImage}
                alt="Fried rice"
                className="img-fluid rounded-3 sushi-image"
              />
            </div>
            
            {/* Text Content Section */}
            <div className="col-md-6">
              <p className="text-pink fw-bold">Healthy and tasty</p>
              <h2 className="fw-bold mb-3">
                HongKong Cuisine brings balance and flavor to everyhh meal.
              </h2>
              <p className="text-muted">
              Try our HongKong-style stri-fry, packed with authentic flavors and fresh ingredients!
              </p>
              <button className="btn btn-primary btn-lg mt-4" onClick={() => navigate('/order')}>Make an order</button>
            </div>
          </div>
        </div>
      );
}
