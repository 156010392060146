import React from 'react';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import './HomePage.css'
import BannerSection from '../components/BannerSection';
import EthosSection from '../components/EthosSection';
import PopularDishes from '../components/PopularDishes';
import AppPromo from '../components/AppPromo';
import Testimonials from '../components/Testimonials';
import Footer from '../components/Footer';

const HomePage = ({restaurant}) => {
  //grab only the dimsum category and pass into dishes
  const dimSumCat = restaurant?.Categories[17]?.Items


  return (
   <div style={{marginTop:'70px'}}>
   <HeroSection />
   <InfoSection />
   <BannerSection />
   <EthosSection />
   <PopularDishes dimsum={dimSumCat} />
   {/* <AppPromo /> */}
   <Footer />
   </div>
  );
};

export default HomePage;
