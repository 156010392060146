import React, { createContext, useState } from 'react';

const Context = createContext();

export const ContextProvider = ({ children }) => {
  const [restaurantId, setRestaurantId] = useState(9);
  const [changedCart, setChangedCart] = useState(false);
  const [language, setLanguage] = useState('en');

  return (
    <Context.Provider value={{ restaurantId, setRestaurantId, changedCart, setChangedCart, language, setLanguage }}>
      {children}
    </Context.Provider>
  );
};

export default Context;
