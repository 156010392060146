import React from 'react';
import { FaAward, FaHeart, FaShippingFast } from 'react-icons/fa';

const EthosSection = () => {
  return (
    <div className="container my-5 ethos-section">
      <div className="text-center mb-5">
        <p className="text-pink fw-bold">A little about us</p>
        <h2 className="fw-bold">Our ethos and service</h2>
      </div>

      <div className="row">
        <div className="col-md-4 d-flex flex-column align-items-center">
          <div className="ethos-card shadow-sm p-4 text-center mt-3 ">
            <div className="icon-circle  mb-3">
              <FaAward color="white" size="2em" />
            </div>
            <h5 className="fw-bold">Quality</h5>
            <p className="text-muted">Enjoy the Quality and authentic flavor in every bite of our HongKong-style stir-fry!</p>
          </div>
        </div>

        <div className="col-md-4 d-flex flex-column align-items-center">
          <div className="ethos-card shadow-sm p-4 text-center mt-4">
            <div className="icon-circle  mb-3">
              <FaHeart color="white" size="2em" />
            </div>
            <h5 className="fw-bold">Healthy</h5>
            <p className="text-muted">Savor the healthy, quality flavors of our HongKong-style stir-fry</p>
          </div>
        </div>

        <div className="col-md-4 d-flex flex-column align-items-center">
          <div className="ethos-card shadow-sm p-4 text-center mt-4">
            <div className="icon-circle  mb-3">
              <FaShippingFast color="white" size="2em" />
            </div>
            <h5 className="fw-bold">Fast</h5>
            <p className="text-muted">Get your HongKong-stytle stir-fry fast, fresh, and flavorful!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EthosSection;
